import React, { Component } from "react"
import { Link } from "gatsby"
import Pixel from "./Pixel";
import { OutboundLink } from "gatsby-plugin-google-analytics";

class ButtonLink extends Component {


    targetLink
    labelLink

    /*
        toPreview(obj) {
            return <ButtonLink link={obj.targetLink} label={obj.labelLink} />
        }
    */
    render() {
        const { label } = this.props;
        // const { target } = this.props;
        const link = this.props.href || '';
        const internal = link.match(/^\//) || link.match(/.*\/federicozanetti.it/) ? true : false;
        const linkClean = internal ? link.replace(/.*federicozanetti.it/, '') : link;
        // const targetted = target || '_self'; //_blank
        const className = this.props.className || "btn py-3 px-5 btn-primary";
        var { title } = this.props;
        if (! title || title === '') {
            title = internal ? linkClean : label;
        } 
        if (link === '')
            return <span></span>
        else
            return (
                <span className="mt-4">
                    {internal ?
                        <Link
                            to={linkClean}
                            className={className}
                            onClick={() => Pixel.trackCustom('GenericButtonClick', { 'url': link, 'label': label })}
                            title={title}
                            >
                            {label}
                        </Link>
                        :
                        <OutboundLink href={link} className={className}>{label}</OutboundLink>
                        // <a href={link} className={className} rel="noopener noreferrer" target={targetted} onClick={() => Pixel.trackCustom('GenericButtonClick', { 'url': link, 'label': label })} >{label}</a>
                    }
                </span>
            )
    }

}

export default ButtonLink