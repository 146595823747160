import React from "react";
import { Component } from "react";
import { Carousel, Col, Container, Row } from "react-bootstrap"
import ButtonLink from "./buttonLink";
import ImageDiv from "./GatsbyImage/ImageDiv";

class Slider extends Component {

    render() {
        const { slider,sections } = this.props
        const elements = slider || sections

        if (elements) {
            return (
                <Container className="text-center container-md">
                    <Row>
                        <Col className="col m-0 p-0">
                            <Carousel fade >
                                {elements.map((item, index) => {
                                    return (
                                        <Carousel.Item key={item.id} id={item.id}>
                                            <ImageDiv
                                                className="d-block w-100"
                                                src={'/' + item.image} alt={item.altImage}
                                            />
                                            <Carousel.Caption className="pb-0">
                                                <h3 className="h3 mb-3">
                                                    {item.link ? <ButtonLink href={item.link} label={item.title} className="btn btn-primary py-1 px-2 font-weight-bold" /> : item.title}
                                                </h3>
                                                {item.body ? <p>{item.body}</p> : ''}
                                            </Carousel.Caption>
                                        </Carousel.Item>
                                    )
                                })}
                            </Carousel>

                        </Col>
                    </Row>
                </Container>
            )
        }
        else {
            return (
                <Container className="mt-2"></Container>
            )
        }
    }
}
export default Slider