import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"



export default function ImageDiv({ data, src, alt, className }) {
  // data, src, className// this prop will be injected by the GraphQL query below.
  
  return (
    <StaticQuery
      query={graphql`
      query {
        allFile(filter: {absolutePath: {regex: "static/assets/"}}) {
           nodes {
             absolutePath
             childImageSharp {
               gatsbyImageData
             }
           }
         }
       }
    `}
      render={data => {
        const filtered = data.allFile.nodes.filter(n => (n.absolutePath.includes(src)));
        if (filtered.length > 0) {
          const image = getImage(filtered[0].childImageSharp)
          return (
            <GatsbyImage image={image} alt={ alt } className={className} width="640" height="360"/>
          )
        } else return <img src={src} className={className} alt={ alt } width="640" height="360"/>
      }}
    />
  )
}
