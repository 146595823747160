import React, { useEffect, useRef, useState } from "react";
import { CSSTransition } from 'react-transition-group';
import useOnScreen from "./useOnScreen";

function Fade({ children }) {

    const ref = useRef()
    const isVisible = useOnScreen(ref)

    const [inProp, setInProp] = useState(false);
    
    useEffect(() => {
        //console.log({ scroll: scrollPosition, posY: posY ,  WindowDimensions : getWindowDimensions()});
        if (!inProp && isVisible) {
            setTimeout(() => {setInProp(true)}, 200)
            //setInProp(true);
        }
    });

    return (
        <CSSTransition
            in={inProp} timeout={500} classNames="fade1"
        >
            <div ref={ref} className="fade1-enter">{children}</div>
        </CSSTransition>
    )

}
export default Fade;