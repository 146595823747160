import { Component } from "react"

class Pixel extends Component {
    /** 
     * general {label + url destinazione}
     * store */
    static track(action, data) {
        this.send('track', action, data);
    }
    static trackCustom(action, data) {
        this.send('trackCustom', action, data);
    }
    static send(track, action, data) {
        //console.log(track + ',' + action + ',' +  JSON.stringify(data));
        
        if (typeof window !== "undefined") {
            if (window.fbq != null) {
                window.fbq(track, action, JSON.stringify(data));
            }
        }
    }
}

export default Pixel